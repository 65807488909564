<template>
    <div
        id="demo"
        class="certificatebyqms"
        data-apiKey="7sANDmIeh9f73dQhy8ptw1QtS0oav/UKdOB/f1ucmNY7V+Mqn2zSzKVtGqp+ZvboOeQ9SM6nLRc3IWO//8im1XtCQzk2MEZDNy1DRDI3LTRFMDAtOEIzRC0xRkEwOEZFNzVCNTB9"
        data-isGreyBadge="false"
    >
    </div>
</template>

<script>
export default {
    name: 'QmsCertificate',
    methods: {
        getISOCertificate() {
            let isoCertificateScript = document.createElement('script');
            isoCertificateScript.setAttribute('data-apiKey', '7sANDmIeh9f73dQhy8ptw1QtS0oav/UKdOB/f1ucmNY7V+Mqn2zSzKVtGqp+ZvboOeQ9SM6nLRc3IWO//8im1XtCQzk2MEZDNy1DRDI3LTRFMDAtOEIzRC0xRkEwOEZFNzVCNTB9');
            isoCertificateScript.setAttribute('src',
                'https://qmsprodstorage.blob.core.windows.net/images/certificate-renderer.js');
            document.head.appendChild(isoCertificateScript);
        },
    },
    mounted() {
        this.getISOCertificate();
    },
};
</script>

<style lang="scss">

.certificatebyqms {
    height: 112px;
    width: 195px;
}

.qmsuk-badge {
    img {
        height: 66px;
        width: 175px;
    }

    span {
        font-size: 11px !important;
    }
}

</style>